import './App.css';

import axios from 'axios';
import { forceUpdate, useState } from "react";

import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

  const host = "jimmysays.api.techtum.dev";

function Say(phrase_id) {
  let audio = new Audio("https://" + host + "/play/" + phrase_id);
  audio.load();
  audio.play();
}

async function addPhrase(user) {
  const new_phrase = prompt('Please enter new phrase...', '');
  if (new_phrase) {
    const payload = {
      "user": user,
      "phrase": new_phrase
    }
    console.log(payload);
    const url = "https://" + host + "/data/phrase";

    await axios.post(url, payload).
      catch((error) => console.log(error));
  }

  const phrs = await getPhrase(user);
  console.log(phrs);
  // re-render - todo
}

async function getPhrase(user) {
    console.log(user);
    const phrs_url = "https://" + host + "/data/" + user;
    //const phrs_url = "/data/" + user;
    console.log(phrs_url);
    let phrs = [];
    await axios.get(phrs_url)
        .then((rs) => {
          phrs = rs.data;
        })
        .catch((error) => {
          console.log(error);
        });
  return phrs;
}

const Hello = () => {
  let [user, setUser] = useState('');
  let [phrases, setPhrases] = useState('');

  
  // do not like the idea of updating on every change
  // so only update on focus lost.
  let onBlur = (ev) => {
    setUser(ev.target.value);
  };
  

  let onClick = async () => {
    const phrs = await getPhrase(user);
    setPhrases(phrs);
  };


  if (phrases.length > 0) {
    console.log(user);
    return (
      <center>
        <PhraseList phrases={phrases}/>
        <Fab icon="+" onClick={() => addPhrase(user)}/>
      </center>
    );
  }

  return (
    <div name="hello" className="hello">
        <center>
          <h1>
            Hello,&nbsp;
          </h1>
          <input type="text" onBlur={onBlur}/>
            &nbsp;
            &nbsp;
          <button
            type="submit"
            onClick={onClick}>
                >>
          </button>
        </center>
    </div>
  );

}

async function onDeletePhrase(id) {
  console.info('onDeletePhrase', id);
  await axios.delete("https://" + host + "/data/phrase/" + id)
}

function PhraseTile(props) {
  console.info('test');
    console.log(props.phrase);
    return (
      <div className="phrase-tile" onClick={(ev) => Say(props.id)}>{props.phrase}<div className="phrase-tile-ctrl" onClick={(ev) => onDeletePhrase(props.id)}><span className="material-symbols-outlined">
delete_forever
</span></div></div>
    );
}

function PhraseList(props) {
  const phrs = props.phrases;
  console.log('phraselist');
  console.log(phrs);

  let list_html = [];
  const phraseList = phrs.map((item) => {
    list_html.push( <PhraseTile phrase={item.phrase} id={item.id}/> );
    list_html.push(' ');

    return list_html;
  });

    return (
      <div className="phrases">
        {phraseList[0]}
      </div>
    );
};



function App() {
  return (
    <>
      <Hello />
    </>
  );
}

export default App;
